<template>

  <!-- Revenue Streams -->
  <div id="story-mode-revenue-streams">

    <!-- Main Navigation -->
    <main-navigation />
    <!-- /Main Navigation -->

    <!-- Container -->
    <div class="container-1200">
      <loader v-if="!loaded.revenueStreams" />

      <!-- Content -->
      <div v-if="loaded.revenueStreams" class="content">
        <v-app>
          <!-- Page Header -->
          <header class="page-header">

            <!-- Dashboard Header -->
            <dashboard-header
              :class="{ 'stamp': isRevenueStreamsCompleted }"
              :title="$t('pages.businessGuide.revenueStreams.title')"
              :guide-visible="guideVisible"
              :guide-visible-first-time="guideVisibleFirstTime"
              :completed-step-name="'revenueStreamsCompleted'"
              hide-quick-tour-button
              @toggle-guide="onGuideExampleVisible"
              @open-comp-dialog="openedDialogProcessCompleted"
              @open-info="openInfo"
              @open-ai-buddy-drawer="aiBuddyDrawerVisible = true"
            />
            <!-- /Dashboard Header -->

            <!-- Divider -->
            <hr>
          <!-- /Divider -->

          </header>
          <!-- /Page Header -->
          <!-- Info Guide Expand Panel -->
          <info-guide
            v-if="guideVisibleFirstTime"
            class="mb-5"
            type="revenueStreamsExamples"
            visible
            @click="guideVisibleFirstTime = !guideVisibleFirstTime"
          />
          <!-- Info Guide Expand Panel -->
        </v-app>

        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <!-- Charts And Table Placeholders -->
        <div v-if="isRevenueStreamsEmpty" class="table-view">

          <!-- Tabs -->
          <el-tabs v-model="activeName">

            <!-- Breakdown -->
            <el-tab-pane :label="$t('breakdown')" name="first">
              <div class="switcher-inner-wrapper">
                <div class="placeholder breakdown" />
              </div>
            </el-tab-pane>
            <!-- /Breakdown -->

            <!-- Timeline -->
            <el-tab-pane :label="$t('timeline')" name="second">
              <div class="switcher-inner-wrapper">
                <div class="placeholder timeline" />
              </div>
            </el-tab-pane>
            <!-- /Timeline -->

            <!-- Table -->
            <el-tab-pane :label="$t('table')" name="third">
              <div class="switcher-inner-wrapper">
                <div class="placeholder table" />
              </div>
            </el-tab-pane>
            <!-- /Table -->

          </el-tabs>
          <!-- /Tabs -->

          <!-- Set Sales Tax Rate Button -->
          <div class="table-btn__wrapper">
            <!-- Completed Overlay -->
            <completed-overlay
              v-if="!canEdit || isRevenueStreamsCompleted"
              @click="checkRevenueStreams"
            />
            <!-- /Completed Overlay -->
            <set-sales-tax-rate />
          </div>
          <!-- /Set Sales Tax Rate Button -->

        </div>
        <!-- /Charts And Table Placeholders -->

        <!-- Charts And Table Filled -->
        <div v-if="!isRevenueStreamsEmpty" class="table-view filled">

          <!-- Tabs -->
          <el-tabs v-model="activeName">

            <!-- Breakdown -->
            <el-tab-pane :label="$t('breakdown')" name="first">
              <div v-if="activeName === 'first'" class="bg-white">
                <revenue-distribution
                  class="pt-4"
                  chart-animations
                />
              </div>
            </el-tab-pane>
            <!-- /Breakdown -->

            <!-- Timeline -->
            <el-tab-pane :label="$t('timeline')" name="second">
              <div v-if="activeName === 'second'" class="bg-white">
                <revenue-over-time
                  class="pt-4"
                  chart-animations
                />
              </div>
            </el-tab-pane>
            <!-- /Timeline -->

            <!-- Table -->
            <el-tab-pane :label="$t('table')" name="third">
              <div v-if="activeName === 'third'">
                <revenue-streams-table class="pt-4 bg-white" />
              </div>
            </el-tab-pane>
            <!-- /Table -->

          </el-tabs>
          <!-- /Tabs -->

          <!-- Set Sales Tax Rate Button -->
          <div class="table-btn__wrapper">
            <!-- Completed Overlay -->
            <completed-overlay
              v-if="!canEdit || isRevenueStreamsCompleted"
              @click="checkRevenueStreams"
            />
            <!-- /Completed Overlay -->
            <set-sales-tax-rate />
          </div>
          <!-- /Set Sales Tax Rate Button -->

        </div>
        <!-- /Charts And Table Filled -->

        <v-app><!-- Header -->
          <business-guide-header>
            <template #right>
              <erase-icon-button
                v-if="removeButtonVisible"
                @click="onEraseFromTemplate"
              />
              <business-guide-search
                v-if="!isRevenueStreamsEmpty"
                v-model="searchValue"
                class="mx-1"
              />
              <!-- View Switcher -->
              <view-switcher
                v-if="!isRevenueStreamsEmpty"
                :active-view="$store.state.itemsView.revenueStreamsView"
                @activate-view="onActivateView"
              />
              <!-- /View Switcher -->
            </template>
          </business-guide-header>
          <!-- /Header -->
        </v-app>

        <!-- Grid Wrapper -->
        <div
          class="grid-wrapper"
          :class="{ 'active': $store.state.itemsView.revenueStreamsView === 'grid' }"
        >
          <el-row :gutter="20" class="d-flex flex-wrap">
            <!-- Completed Overlay -->
            <completed-overlay
              v-if="!canEdit || isRevenueStreamsCompleted"
              @click="checkRevenueStreams"
            />
            <!-- /Completed Overlay -->
            <!-- Add Revenue Stream -->
            <el-col :md="8" :xl="6" class="d-flex width-lg-20 mb-4 position-relative">
              <add-new-card
                class="width-xl-20"
                :title="$tc('addRevenueStream')"
                :disabled="!canEdit || isRevenueStreamsCompleted"
                min-height="345px"
                @click="onAddRevenueStream"
              />
            </el-col>
            <!-- /Add Revenue Stream -->

            <!-- Grid Items -->
            <el-col
              v-for="revenueStream in revenueStreamToShowGrid"
              :key="revenueStream.id"
              :md="8" :xl="6"
              class="d-flex width-lg-20 mb-4"
            >
              <div class="d-flex w-100" @click="checkRevenueStreams">
                <set-up-and-projections-card
                  class="w-100"
                  :entity="revenueStream"
                  :name="revenueStream.name"
                  :date="`${getFormattedDate(revenueStream.starts)} - ${getFormattedDate(revenueStream.ends)}`"
                  :amount="getFormattedAmount(revenueStream.average)"
                  :frequency="$t('pages.businessGuide.monthlyAverage')"
                  :type="revenueStream.type"
                  :disabled="isRevenueStreamsCompleted"
                  @edit="onEditRevenueStreams"
                  @copy="onCopyRevenueStreams"
                  @delete="onDeleteRevenueStreams"
                />
              </div>
            </el-col>
            <!-- /Grid Items -->

          </el-row>
          <!-- Show All -->
          <el-row v-if="$store.state.idea.storyMode.forecast.revenueStreams.revenueStreams.length > 4">
            <el-col>
              <show-all-toggle
                :show="showAllRevenueStreamsGrid"
                @click="showAllRevenueStreamsGrid = !showAllRevenueStreamsGrid"
              />
            </el-col>
          </el-row>
          <!-- /Show All -->
        </div>
        <!-- /Grid Wrapper -->

        <!-- List Wrapper -->
        <div
          class="list-wrapper"
          :class="{ 'active': $store.state.itemsView.revenueStreamsView === 'list' }"
        >
          <el-row :gutter="20" class="d-flex flex-wrap">
            <!-- Completed Overlay -->
            <completed-overlay
              v-if="!canEdit || isRevenueStreamsCompleted"
              @click="checkRevenueStreams"
            />
            <!-- /Completed Overlay -->
            <!-- Add Revenue Stream -->
            <el-col :md="12" class="d-flex position-relative mb-2">
              <add-new-card
                class="width-xl-20 py-2 py-md-0"
                :title="$tc('addRevenueStream')"
                :disabled="!canEdit || isRevenueStreamsCompleted"
                horizontal
                @click="onAddRevenueStream"
              />
            </el-col>
            <!-- /Add Revenue Stream -->

            <!-- Placeholder 1 -->

            <el-col
              v-if="isRevenueStreamsEmpty && $breakpoint.mdAndUp"
              :md="12"
            >
              <div class="placeholder blue">
                <div class="inner" />
              </div>
            </el-col>
            <!-- /Placeholder 1 -->

            <!-- Placeholder 2 -->

            <el-col
              v-if="isRevenueStreamsEmpty && $breakpoint.mdAndUp"
              :md="12"
            >
              <div class="placeholder blue">
                <div class="inner" />
              </div>
            </el-col>
            <!-- /Placeholder 2 -->

            <!-- Placeholder 3 -->
            <el-col
              v-if="isRevenueStreamsEmpty && $breakpoint.mdAndUp"
              :md="12"
            >
              <div class="placeholder yellow">
                <div class="inner" />
              </div>
            </el-col>
            <!-- /Placeholder 3 -->

            <!-- Tutorial Tooltip  TODO - Currently disabled in if directive -->
            <tutorial-tooltip
              v-if="$store.state.user.tutorial.mainBottomBarCompleted === false && 1 === 2"
              :title="$t('pages.businessGuide.revenueStreams.addCustomersWantToTarget')"
              :text="$t('pages.businessGuide.thisIsStarOfYourShow')"
              :tutorial-name="'RevenueStreamsCompleted'"
            />
            <!-- /Tutorial Tooltip -->

            <!-- List Items -->
            <el-col
              v-for="revenueStream in revenueStreamToShowList"
              :key="revenueStream.id"
              :md="12"
              class="mb-2"
            >
              <div @click="checkRevenueStreams">
                <set-up-and-projections-card
                  :entity="revenueStream"
                  :name="revenueStream.name"
                  :date="`${getFormattedDate(revenueStream.starts)} - ${getFormattedDate(revenueStream.ends)}`"
                  :amount="getFormattedAmount(revenueStream.average)"
                  :frequency="$t('pages.businessGuide.monthlyAverage')"
                  :type="revenueStream.type"
                  :disabled="isRevenueStreamsCompleted"
                  view="list"
                  @edit="onEditRevenueStreams"
                  @copy="onCopyRevenueStreams"
                  @delete="onDeleteRevenueStreams"
                />
              </div>
            </el-col>
            <!-- /List Items -->
          </el-row>
          <!-- Show All -->
          <el-row v-if="$store.state.idea.storyMode.forecast.revenueStreams.revenueStreams.length > 7">
            <el-col>
              <show-all-toggle
                :show="showAllRevenueStreamsList"
                @click="showAllRevenueStreamsList = !showAllRevenueStreamsList"
              />
            </el-col>
          </el-row>
          <!-- /Show All -->
        </div>
        <!-- /List Wrapper -->

      </div>
      <!-- /Content -->

      <!-- Add More Details -->
      <add-more-details
        :category="'forecast'"
        :step="'revenue-stream'"
        :concept-name="'revenueStreamsMoreDetails'"
        :completed-step-name="'revenueStreamsCompleted'"
      />
      <!-- /Add More Details -->

    </div>
    <!-- /Container -->

    <!-- Main Bottom Bar -->
    <!-- TODO: step-filled should be populated -->
    <main-bottom-bar
      :back="backNavigation"
      :next="nextNavigation"
      :completed-step-name="'revenueStreamsCompleted'"
      :step-filled="$store.state.idea.storyMode.forecast.revenueStreams.revenueStreams.length > 0"
      @complete-step="onCompleteStep"
    />
    <!-- /Main Bottom Bar -->

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="revenueStreamsExamples"
      :visible="guideVisible"
      @close="guideVisible = false"
    />
    <!-- /Info Guide Drawer -->

    <!-- Ai Buddy Drawer -->
    <ai-buddy-drawer
      :visible="aiBuddyDrawerVisible"
      type="revenue-stream"
      @close="aiBuddyDrawerVisible = false"
    />
    <!-- /Ai Buddy Drawer -->

    <!-- Dialog Add Revenue Stream -->
    <dialog-add-revenue-stream
      :dialog-add-revenue-stream-visible="dialogAddRevenueStreamVisible"
      :revenue-stream-action="revenueStreamAction"
      @close-dialog-add-revenue-streams="onCloseDialogAddRevenueStream"
      @close-dialog-on-button="dialogAddRevenueStreamVisible = false"
      @on-update-revenue-streams-view="getRevenueStreams"
    />
    <!-- /Dialog Add Revenue Stream -->

    <!-- Dialog Remove Revenue Stream -->
    <dialog-remove-revenue-stream
      :dialog-remove-revenue-stream-visible="dialogRemoveRevenueStreamVisible"
      :revenue-stream-action-to-delete="revenueStreamActionToDelete"
      @close-dialog-remove-revenue-stream="onCloseDialogRemoveRevenueStream"
      @on-update-revenue-streams-view="getRevenueStreams"
    />
    <!-- /Dialog Remove Revenue Stream -->

    <!-- Dialog Process Completed -->
    <dialog-process-completed
      :dialog-process-completed-visible="dialogProcessCompletedVisible"
      @close-dialog-process-completed="onCloseDialogProcessCompleted"
    />
    <!-- /Dialog Process Completed -->

    <!-- Dialog Remove Examples -->
    <dialog-remove-examples
      :visible="dialogRemoveExamplesVisible"
      :loading="removeExamplesLoading"
      @no="dialogRemoveExamplesVisible = false"
      @yes="deleteExamples"
    />
    <!-- /Dialog Remove Examples -->

  </div>
  <!-- /Revenue Streams -->

</template>

<script>
import AddMoreDetails from '@/views/Home/StoryMode/Components/AddMoreDetails'
import AddNewCard from '@/components/_v2/AddNewCard'
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import BusinessGuideHeader from '@/components/_v2/Common/BusinessGuideHeader'
import CompletedOverlay from '@/views/Home/StoryMode/Components/CompletedOverlay'
import DashboardHeader from '@/views/Home/StoryMode/Components/DashboardHeader'
import DialogAddRevenueStream from './Dialogs/DialogAddRevenueStream'
import DialogProcessCompleted from '@/views/Home/StoryMode/Components/Dialogs/DialogProcessCompleted'
import DialogRemoveExamples from '@/views/Home/StoryMode/Components/DialogRemoveExamples'
import DialogRemoveRevenueStream from '@/views/Home/StoryMode/Forecast/RevenueStreams/Dialogs/DialogRemoveRevenueStream'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import Loader from '@/components/Loader'
import MainBottomBar from '@/views/Home/StoryMode/Components/MainBottomBar/MainBottomBar'
import MainNavigation from '@/views/Home/StoryMode/Components/MainNavigation/MainNavigation'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinCurrencies from '@/mixins/currencies'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinEraseTemplate from '@/mixins/eraseTemplate'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinProcessCompleted from '@/mixins/processCompleted'
import RevenueDistribution from '@/views/Home/StoryMode/FinancialPlan/Components/Revenue/RevenueDistribution'
import RevenueOverTime from '@/views/Home/StoryMode/FinancialPlan/Components/Revenue/RevenueOverTime'
import RevenueStreamsTable from '@/views/Home/StoryMode/Forecast/RevenueStreams/Tables/RevenueStreamsTable'
import SetSalesTaxRate from '@/views/Home/StoryMode/Forecast/RevenueStreams/SetSalesTaxRate'
import SetUpAndProjectionsCard from '@/views/Home/StoryMode/Components/Cards/SetUpAndProjectionsCard'
import ShowAllToggle from '@/views/Home/StoryMode/Components/ShowAllToggle'
import TutorialTooltip from '@/views/Home/Components/TutorialTooltip'
import ViewSwitcher from '@/components/_v2/ViewSwitcher'

import { mapActions, mapGetters, mapState } from 'vuex'
import BusinessGuideSearch from '@/views/Home/Components/BusinessGuideSearch.vue'
import InfoGuide from '@/views/Home/StoryMode/Components/Example/InfoGuide.vue'
import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'

export default {
  name: 'RevenueStreams',

  components: {
    InfoGuide,
    AiBuddyDrawer,
    InfoGuideDrawer,
    BusinessGuideSearch,
    AddMoreDetails,
    AddNewCard,
    BusinessGuideHeader,
    CompletedOverlay,
    DashboardHeader,
    DialogAddRevenueStream,
    DialogProcessCompleted,
    DialogRemoveExamples,
    DialogRemoveRevenueStream,
    EraseIconButton,
    Loader,
    MainBottomBar,
    MainNavigation,
    RevenueDistribution,
    RevenueOverTime,
    RevenueStreamsTable,
    SetSalesTaxRate,
    SetUpAndProjectionsCard,
    ShowAllToggle,
    TutorialTooltip,
    ViewSwitcher
  },

  mixins: [
    MixinCurrencies,
    MixinProcessCompleted,
    MixinGuide,
    MixinApiCalculations,
    MixinDeleteIdeaExamples,
    MixinIdeaRoles,
    MixinEraseTemplate
  ],

  data () {
    return {
      aiBuddyDrawerVisible: false,
      searchValue: '',
      dialogRemoveExamplesVisible: false,
      removeExamplesLoading: false,
      revenueStreamActionToDelete: null,
      dialogRemoveRevenueStreamVisible: false,
      showAllRevenueStreamsGrid: false,
      showAllRevenueStreamsList: false,
      revenueStreamAction: null,
      completed: false,
      dialogAddRevenueStreamVisible: false,
      dialogProcessCompletedVisible: false,
      activeName: 'first',
      showMoreItemsButtonText: 'more',
      itemsToShow: 3,
      guideVisible: false
    }
  },

  computed: {
    ...mapState(['idea']),

    ...mapGetters('idea', {
      revenueStreams: 'getRevenueStreams',
      getStepIsCompleted: 'getStepIsCompleted',
      getHasItemsFromTemplate: 'getHasItemsFromTemplate'
    }),

    isRevenueStreamsEmpty () {
      return !this.revenueStreams.length
    },

    isRevenueStreamsCompleted () {
      return this.getStepIsCompleted('revenueStreamsCompleted')
    },

    revenueStreamToShowGrid () {
      if (this.searchValue !== '' && this.searchValue) {
        return this.filterRevenueStreams
      }

      return this.showAllRevenueStreamsGrid === true
        ? this.revenueStreams
        : this.revenueStreams.slice(0, 4)
    },

    revenueStreamToShowList () {
      if (this.searchValue !== '' && this.searchValue) {
        return this.filterRevenueStreams
      }

      return this.showAllRevenueStreamsList === true
        ? this.revenueStreams
        : this.revenueStreams.slice(0, 7)
    },

    filterRevenueStreams () {
      return this.revenueStreams.filter(item => item.name.toUpperCase().includes(this.searchValue.toUpperCase()))
    },

    ideaStartingFrom () {
      return this.idea.ideaSettings.startingFrom
    },

    removeButtonVisible () {
      return this.canEdit && this.getHasItemsFromTemplate('getRevenueStreams')
    },

    backNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.ownership'),
        route: 'business-guide-set-up-ownership'
      }
    },

    nextNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.directCosts'),
        route: 'business-guide-projections-direct-costs'
      }
    }
  },

  watch: {
    ideaStartingFrom () {
      this.getRevenueStreams()
    }
  },

  async created () {
    this.openGuide('revenueStreamGuide')
    await this.getRevenueStreams()
    await this.getRevenueStreamsMoreDetails()
  },

  methods: {
    ...mapActions('idea', [
      'getRevenueStreamsMoreDetails',
      'deleteIdeaExamples'
    ]),

    onAddRevenueStream () {
      if (!this.canEdit) return
      this.dialogAddRevenueStreamVisible = true
    },

    checkRevenueStreams () {
      if (!this.canEdit) return
      if (this.isRevenueStreamsCompleted) {
        this.dialogProcessCompletedVisible = true
      }
    },

    getFormattedDate (date) {
      return this.$moment(date).format('MMM YYYY')
    },

    onGuideExampleVisible () {
      this.guideVisible = !this.guideVisible
    },

    onActivateView (view) {
      this.$store.commit('itemsView/setRevenueStreamsView', view)
    },

    onCloseDialogAddRevenueStream () {
      this.dialogAddRevenueStreamVisible = false
      this.revenueStreamAction = null

      // Workaround to update computed property productsToShowGrid & productsToShowList
      this.updateRevenueStreamView()
    },

    onEditRevenueStreams (revenueStream) {
      this.revenueStreamAction = Object.assign({}, revenueStream)
      this.dialogAddRevenueStreamVisible = true
    },

    onCopyRevenueStreams (revenueStream) {
      this.revenueStreamAction = Object.assign({}, revenueStream)
      this.revenueStreamAction.id = 0
      this.dialogAddRevenueStreamVisible = true
    },

    onDeleteRevenueStreams (revenueStream) {
      this.revenueStreamActionToDelete = Object.assign({}, revenueStream)
      this.dialogRemoveRevenueStreamVisible = true
    },

    onCloseDialogRemoveRevenueStream () {
      this.dialogRemoveRevenueStreamVisible = false
      this.revenueStreamActionToDelete = null
      this.updateRevenueStreamView()
    },

    updateRevenueStreamView () {
      this.showAllRevenueStreamsList = !this.showAllRevenueStreamsList
      this.showAllRevenueStreamsList = !this.showAllRevenueStreamsList
      this.showAllRevenueStreamsGrid = !this.showAllRevenueStreamsGrid
      this.showAllRevenueStreamsGrid = !this.showAllRevenueStreamsGrid
    },

    onEraseFromTemplate () {
      if (this.isRevenueStreamsCompleted) {
        this.openedDialogProcessCompleted()

        return
      }
      this.dialogRemoveExamplesVisible = true
    },

    deleteExamples () {
      this.removeExamplesLoading = true
      this.deleteIdeaExamples('revenue-streams')
        .then(response => {
          const { type, ids } = response
          this.deleteFromState({ type, ids })
          this.getRevenueStreams()
          this.dialogRemoveExamplesVisible = false
          this.removeExamplesLoading = false
        }).catch(_ => { this.removeExamplesLoading = false })
    }
  }
}
</script>

<style scoped>
  #story-mode-revenue-streams {
    margin-left: 0;
  }
</style>
